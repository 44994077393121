.auth {
  &__container {
    flex-direction: column;
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 25px auto;
    padding: 0;
    background: $light-blue;
    background-image: url('/images/background-2024.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    .wrap-login-form {
      padding: 0 20px;
    }

    .log-in {
      font-family: BNPPSansCondensed, sans-serif;
      font-size: 3rem;
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $black;
      padding-top: 20px;
      margin: 0px;
      padding-bottom: 40px;
      text-align: center;

      @include breakpoint('>=', 'ts') {
        padding-top: 80px;
      }
    }
    .log-in-secondary {
      font-family: BNPPSansCondensed, sans-serif;
      font-size: 36px;
      font-weight: normal;
      font-stretch: condensed;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $main-color;
      text-transform: uppercase;
      padding-top: 0;
      margin: 0px;
      padding-bottom: 40px;
    }
    .log-in-dates {
      font-family: BNPPSansCondensed, sans-serif;
      font-size: 26px;
      font-weight: normal;
      font-stretch: condensed;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      padding-top: 0;
      margin: 0px;
      padding-bottom: 20px;
    }
    .intro {
      font-family: BNPPSans-Light, sans-serif;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      color: $black;
      margin: 0px;
      padding-bottom: 20px;
    }
    .box-input {
      .btn-submit {
        background-color: #e6007d;
        // width: 82px;
        // height: 40px;
        border: 1px solid transparent;
        border: none;
        font-family: BNPPSansCondensed;
        font-size: 1.5rem;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        padding: 12px 25px 6px 25px;
        margin-bottom: 12px;

        @include breakpoint('>=' , 'tl') {
          font-size: 2rem;
        }
      }
      .btn-login-sso {
        text-decoration: none;
        display: block;
        width: 100%;
      }
      .btn-login-email {
        text-decoration: none;
        background-color: $secondary-color;
        display: block;
        width: 100%;
      }
      .btn-login-no-account {
        font-family: BNPPSans-Light, sans-serif;
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #e90088;
        text-decoration: underline;
      }
      .email-login {
        height: 52px;
        border: solid 1px $white;
        background-color: $white;
        border-radius: 0;
        color: $main-color;
      }
      .email-login::placeholder {
        font-family: $font-family;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $main-color;
      }
      .email-login.has-error {
        border: solid 1px #b41d18;
        color: #b41d18;
      }
      .error-text {
        margin-top: 14px;
        font-family: BNPPSans, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #b41d18;
      }
      .select-group {
        background-image: url('/images/auth/down-chevron.svg');
        background-repeat: no-repeat;
        background-position: 97%;
        background-size: 20px 20px;
        border-radius: 0px;
        font-family: BNPPSans-Light, sans-serif;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        min-height: 52px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
      }
      .select-group::-ms-expand {
        display: none;
      }
    }
    .logo-apple {
      position: relative;
      .bite-size-logo {
        position: absolute;
        bottom: 7%;
        right: 10%;
        z-index: 1000;
        width: 40%;
        height: 40%;
      }
    }
  }

  &__content {
    padding: 20px;
    width: 100%;

    @include breakpoint('>=', 'tm') {
      justify-content: center;
      padding: 20px 0;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 25px;
    margin: 0;

    @include breakpoint('>=', 'tm') {
      font-size: 40px;
    }
  }

  &__labels {
    text-align: center;

    @include breakpoint('>=', 'tm') {
      text-align: left;
    }
  }

  &__header {
    text-align: center;
  }

  &__cta__wrap {
    margin-top: 20px;
    text-align: center;
    color: $white;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include breakpoint('>=', 'tm') {
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__cta__link {
    margin-top: 15px;
    margin-bottom: 15px;
    color: $white;

    &:hover,
    &:focus {
      color: $secondary-color;
    }

    @include breakpoint('>=', 'tm') {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: auto;
    }
  }

  &__cta__btn {
    text-align: center;

    @include breakpoint('>=', 'tm') {
      text-align: right;
      margin-left: auto;
    }
  }

  &__logo {
    width: 40%;
    margin: 0 auto 30px;
    max-height: 130px;
    flex-shrink: 0;
  }

  &__terms {
    color: $white;
    font-family: $font-family;
    font-size: 12px;
    width: 100%;
  }

  &__link {
    text-decoration: underline;
    color: $dark;
  }

  &__header {
    width: 100%;
  }

  &__icons {
    display: none;
    position: absolute;
    max-height: 450px;
    max-width: calc(50% - 30px);
    right: 0;
    bottom: 0;
    margin-right: 30px;

    @include breakpoint('>=', 'ts') {
      display: block;
    }
  }

  &__sign {
    position: relative;
    max-height: 156px;
    display: flex;
    margin: 20px 30px 0 auto;

    @include breakpoint('>=', 'ts') {
      position: absolute;
      max-height: 200px;
      right: 0;
    }
  }

  &__title {
    position: absolute;
    right: 20px;
    top: 6%;
    transform: translateY(-20%);
    color: $white;
    font-family: $font-family-bold-b;
    font-size: 40px;
  }

  &__calendar {
    @include breakpoint('>=', 'ts2') {
      left: -40px;
      top: 180px;
    }

    @include breakpoint('>=', 'tl') {
      left: -90px;
      top: 100px;
    }

    &-icon {
      width: 53px;
    }

    &-content {
      max-width: 275px;
      font-family: BNPPSansCondensed, sans-serif;
      font-size: 19px;
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $black;
      margin: 0px;
    }
  }
}
