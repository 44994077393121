// Overrides Bootstrap
.tab {
  &__container {
    background-color: transparent;

    @include breakpoint('>=', 'tm') {
      background-color: rgba(0, 0, 0, 0.2);
      margin: 0 -1000px;
      padding: 60px 1000px;
    }
  }

  &__files {
    background: transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: $white;
    border: 1px solid #ffffff;
  }

  &__list {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    display: flex;
    flex-direction: row!important;
    justify-content: flex-start;
    padding: 15px 5px;
    max-width: 100%;
  }

  &__content {
    padding: 20px 0!important;
  }

  &__item {
    text-align: center;
    flex-grow: 0!important;

    @include breakpoint('<=', 'tm') {
      padding: 15px 10px!important;
    }
  }

  &__link {
    background-color: transparent;
    border: none!important;
    color: $light;

    &:hover,
    &:focus {
      border-bottom: none;
      font-family: $font-family-bold;
    }

    &.active {
      background-color: transparent!important;
      border-bottom: 2px solid $white!important;
      color: $light!important;
      font-family: $font-family-bold;
      padding-top: 10px;
    }
  }
}
