body {
  background-color: $light;
  box-sizing: border-box;
  min-height: 100vh;
  font-family: $font-family-body;
}

hr {
  border-color: $light;
}

a {
  color: $light;
  letter-spacing: 0.4px;

  &:hover {
    color: $light;
  }

  &:focus {
    color: darken($light, 10%);
  }

  &:not([href]) {
    color: initial;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-light;
  line-height: 1.2;
  letter-spacing: 0.4px;
}

p {
  letter-spacing: 0.4px;
  line-height: 1.2;
}

// !important overrides for BS
button {
  background-color: $grey-mid;
  border: 2px solid $grey-mid;
  color: $dark;
  font-family: $font-family-body;
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  padding: 18px 30px;
  border-radius: 4px;
  margin-bottom: 15px;
  transition: all .2s linear;
  cursor: pointer;
  outline: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: #808791f5;
    border: 2px solid #808791f5;
    color: $dark;
    outline: 0!important;
  }
}
