@import 'resources/scss/theme/0-toolkit/_base.scss';
@import 'resources/scss/theme/0-toolkit/_colors.scss';
@import 'resources/scss/theme/0-toolkit/_mixins.scss';
@import 'resources/scss/theme/0-toolkit/_breakpoints.scss';

$font-family: 'BNPPSans', sans-serif;
$font-family-body: 'BNPPSans-Light', sans-serif;
$font-family-light: 'BNPPSans-Light', sans-serif;
$font-family-bold: 'BNPPSansCondensed-Bold', sans-serif;
$font-family-condensed: 'BNPPSansCondensed', sans-serif;

.wrap-provider-page {
  .wrap-banner {
    min-width: 100%;
    position: relative;
    background: $light-blue;
    background-image: url('/images/background-2024.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    margin-left: -30px;
    margin-right: -30px;

    @include breakpoint('>=', 'll') {
      margin-left: -15px;
      margin-right: -15px;
    }

    .event-title {
      position: absolute;
      color: $white;
      font-family: 'BNPPSansCondensed-Bold2', sans-serif;
      right: 20px;
      top: 0px;
      font-size: 20px;

      @include breakpoint('>=', 'ml') {
        font-size: 30px;
        right: 30px;
        top: 10px;
      }

      @include breakpoint('>=', 'ts') {
        font-size: 45px;
        right: 30px;
        top: 10px;
      }
    }

    .img-fluid {
      width: 100%;
      height: auto;
      padding: 0 14px;
    }

    .banner-cta {
      padding: 0;
      width: 100%;

      @include breakpoint('>=', 'ts') {
        padding-left: 10%;
        padding-bottom: 3rem;
      }

      .btn-change-site {
        font-family: BNPPSansCondensed-Light, sans-serif;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        background-color: $main-color;
        display: block;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.8px;
        width: 100%;
        padding: 8px 35px 6px 35px;

        @include breakpoint('>=', 'ts') {
          background-color: $main-color;
          color: $white;
          font-size: 20px;
          padding: 15px 35px 13px 35px;
          width: fit-content;
        }
      }
    }

    .banner-sign {
      position: absolute;
      max-width: 70px;
      top: 0;
      right: 0;
      margin: 10px 10px 0 0;

      @include breakpoint('>=', 'ts') {
        max-width: 156px;
        margin: 20px 20px 0 0;
      }
    }

    .banner-icons {
      display: none;
      position: absolute;
      max-width: 20%;
      bottom: 0;
      right: 10%;

      @include breakpoint('>=', 'ts') {
        display: block;
      }
    }

    .intro {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 47%;
      bottom: 4vw;
      margin: auto 0;
      left: 40px;

      .welcome {
        font-family: BNPPSansCondensed, sans-serif;
        font-size: 36px;
        font-weight: bold;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $off-black;
        margin: 0px;
        text-transform: uppercase;
        width: 100%;

        @include breakpoint('>=', 'ts2') {
          color: $white;
        }

        @include breakpoint('>=', 'ds') {
          font-size: 51px;
        }
      }

      .description {
        margin-top: 10px;

        @include breakpoint('>=', 'tl') {
          margin-top: 24px;
        }

        p {
          font-family: BNPPSans-Light, sans-serif;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: $off-black;

          @include breakpoint('>=', 'ts2') {
            color: $white;
          }

          @include breakpoint('>=', 'ds') {
            font-size: 18px;
          }
        }
      }
    }
  }

  .wrap-provider-list {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;

    .wrap-provider-card {
      max-width: 430px;
    }

    .wrap-provider-item {
      padding-bottom: 18px;
      max-width: 500px;
      min-height: 100%;
      display: flex;
      flex-direction: column;

      .thumbnail {
        width: 100%;
        position: relative;
        padding: 0px;
        margin-bottom: 0px;
        border: 0px;

        .img-fluid {
          width: 100%;
          object-fit: contain;
        }
      }

      .event-date {
        z-index: 1000;
        position: absolute;
        top: 10px;
        right: 10px;
        border: solid 2px $white;
        background-color: transparent;
        min-width: 44px;

        .month {
          background-color: $primary;
          padding: 5px 5px 2px 5px;

          h3 {
            font-family: BNPPSansCondensed-Bold, sans-serif;
            font-size: 12px;
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            letter-spacing: 0.5px;
            text-align: center;
            color: $white;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
          }
        }

        .day {
          text-align: center;
          background-color: $white;
          padding: 3px;

          h3 {
            font-family: BNPPSansCondensed-Bold, sans-serif;
            font-size: 27px;
            font-weight: bold;
            font-stretch: condensed;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $black;
            text-transform: uppercase;
            margin: 0;
            padding-top: 5px;
          }
        }
      }

      .wrap-detail {
        background-color: $primary;
        padding: 15px;
        flex: 1;

        .title {
          font-family: $font-family-bold;
          font-size: 17px;
          font-weight: bold;
          font-stretch: condensed;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: $white;
          text-transform: uppercase;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;

          @media (min-width: 576px) {
            height: 36px;
          }
        }

        .description {
          p {
            font-family: BNPPSans-Light, sans-serif;
            font-size: 13px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: $white;
            white-space: normal;
            overflow: hidden;
            margin: 0px;
          }
        }

        .group-btn {
          text-align: right;

          .btn-item {
            margin-bottom: 5px;
          }
        }

        .btn-view-profile {
          border: solid 1px $white;
          background-color: $white;
          font-family: BNPPSansCondensed, sans-serif;
          font-size: 16px;
          font-weight: 300;
          font-stretch: condensed;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-align: center;
          color: $black;
          padding: 7px 8px 5px 8px;
          text-transform: uppercase;
        }

        .btn-add-to-calendar {
          background-color: transparent;
          font-family: BNPPSansCondensed, sans-serif;
          font-size: 16px;
          font-weight: 300;
          font-stretch: condensed;
          font-style: normal;
          line-height: normal;
          text-align: center;
          color: $white;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          border: solid 1px $white;
          padding: 7px 8px 5px 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1388px) {
  .wrap-provider-page {
    .wrap-banner {
      .intro {
        width: 48%;

        .welcome {
          font-size: 34px;
        }

        .description {
          p {
            font-size: 14px;
            line-height: 1.2;
          }
        }

        .btn-change-site {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .wrap-provider-page {
    .wrap-banner {
      .intro {
        width: 60%;

        .welcome {
          font-size: 30px;
        }

        .description {
          margin-top: 6px;

          p {
            font-size: 13px;
            line-height: 1.2;
          }

          .btn-change-site {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 742px) {
  .wrap-provider-page {
    .wrap-banner {
      .intro {
        margin-top: 10px;
        display: block;
        position: relative;
        width: 100%;
        top: unset;
        left: unset;
        padding: 15px;

        .welcome {
          font-size: 18px;
          text-align: center;
        }

        .description {
          p {
            font-size: 13px;
            line-height: 1.2;
          }

          .btn-change-site {
            font-size: 13px;
            padding: 10px;
            width: 100%;
          }
        }
      }
    }
  }
}

.tooltip .tooltip-inner {
  font-family: BNPPSans-Light, sans-serif;
}
