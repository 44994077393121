#registration-form {
  .registration-form__section {
    background-color: rgba(1, 1, 1, 0.1);
    padding: 15px;
    border-radius: 25px;
    margin-bottom: 20px;

    #attending-btns a {
      margin-bottom: 0;
    }

    .link {
      text-decoration: underline;
    }

    h3 {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 0;
      margin-bottom: 20px;
    }

    .form-group {
      margin-bottom: 0;

      &:not(:first-of-type) {
        margin-top: 30px;
      }
    }
  }
}
