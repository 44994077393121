.file-upload {
  position: relative;
  overflow: hidden;
}

.file-upload .attachment_upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 33px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.img-preview {
  max-width: 20%;
}
.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}