.page {
  &--light {
    // @include primary-gradient(133deg);
    background-color: $white;
    color: $dark;
  }

  &--dark {
    // @include primary-gradient(133deg);
    background-color: $white;
    color: $light;

    a {
      color: $grey;
    }
  }

  &--overlay {
    &:after {
      content: '';
      display: block;
      background-image: linear-gradient(134deg, rgba(8, 176, 160, 0.4), rgba(54, 169, 225, 0.4));
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 2;
      transition: all 0.2s linear;
    }
  }

  &__wrapper {
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: auto;
  }

  &__banner {

    &.container-fluid {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
