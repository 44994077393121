.wrap-faqs-page {
  width: 100%;

  .top-bar {
    width: 100%;
    height: 25px;
    background-image: url('/images/auth/top-bar.svg');
  }

  .wrap-banner {
    min-width: 100%;
    position: relative;
    background: $light-blue;
    background-image: url('/images/background-2024.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    margin-left: -30px;
    margin-right: -30px;

    @include breakpoint('>=', 'll') {
      margin-left: -15px;
      margin-right: -15px;
    }

    .event-title {
      position: absolute;
      color: #ffffff;
      font-family: 'BNPPSansCondensed-Bold2', sans-serif;
      right: 20px;
      top: 0px;
      font-size: 20px;

      @include breakpoint('>=', 'ml') {
        font-size: 30px;
        right: 30px;
        top: 10px;
      }

      @include breakpoint('>=', 'ts') {
        font-size: 45px;
        right: 30px;
        top: 10px;
      }
    }

    .img-fluid {
      width: 100%;
    }

    .bite-size-logo {
      position: absolute;
      top: 26px;
      right: 26px;
      z-index: 1000;
      height: 35%;
    }

    .banner-sign {
      position: absolute;
      max-width: 70px;
      top: 0;
      right: 0;
      margin: 10px 10px 0 0;

      @include breakpoint('>=', 'ts') {
        max-width: 156px;
        margin: 20px 20px 0 0;
      }
    }

    .banner-icons {
      display: none;
      position: absolute;
      max-width: 20%;
      bottom: 0;
      right: 10%;

      @include breakpoint('>=', 'ts') {
        display: block;
      }
    }

    .intro {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      text-align: center;
      padding: 20px 0 10px 0;

      @include breakpoint('>=', 'ms') {
        position: absolute;
        text-align: left;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 40px;
        width: 45%;
        padding: 0;
      }

      .welcome {
        font-family: BNPPSansCondensed, sans-serif;
        font-size: 72px;
        font-weight: bold;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $off-black;
        margin: 0px;
        text-transform: uppercase;

        @include breakpoint('>', 'ms') {
          color: $white;
        }

        @include breakpoint('>', 'tl') {
          margin-bottom: 24px;
        }
      }

      .description {
        p {
          font-family: BNPPSans-Light, sans-serif;
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $off-black;

          @include breakpoint('>', 'ms') {
            color: $white;
          }

          @include breakpoint('>=', 'ds') {
            font-size: 18px;
          }
        }
      }
    }
  }

  .wrap-faq-content {
    width: 100%;
    padding: 0 0 20px;

    @include breakpoint('>', 'ms') {
      padding-top: 50px;
    }

    @include breakpoint('>=', 'ls') {
      padding: 50px 0 60px;
    }

    .wrap-list-contact {
      padding-top: 30px;

      @include breakpoint('>=', 'ts') {
        padding-top: 0;
      }
    }

    .wrap-list-faqs {
      .card {
        border: 0px;
      }
      .parent-card {
        .card-header {
          border-radius: 0px;
          background-color: $light-blue;
          padding: 0;
          border: 0px;
          cursor: pointer;
          a {
            display: block;
            padding: 5px 20px;
            width: 100%;
            font-family: BNPPSansCondensed, sans-serif;
            font-size: 19px;
            font-weight: normal;
            font-stretch: condensed;
            font-style: normal;
            letter-spacing: normal;
            color: $white;
            text-transform: uppercase;
          }
        }
        .card-body {
          border-radius: 0px;
          border: 0px;
          background-color: #ffffff;
          padding: 5px 0px 5px 0px;
        }
      }
      .child-card {
        margin-bottom: 5px;
        .card-header {
          border-radius: 0px;
          background-color: #f2f2f2;
          padding: 0;
          border: 0px;
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: 98%;
          background-size: 20px;
          background-image: url('/images/faqs/chevron-down.svg');
          padding-right: 2%;

          a {
            display: block;
            padding: 20px 20px;
            font-family: BNPPSans, sans-serif;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $main-color;
            text-transform: initial;
          }
        }
        .card-body {
          border-radius: 0px;
          border: 0px;
          background-color: #f2f2f2;
          padding: 10px 20px;
          font-family: BNPPSans-Light, sans-serif;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
        }
      }
    }
    .wrap-question-request {
      position: relative;
      background-color: #f2f2f2;
      padding: 22px 14px 14px 14px;

      background-image: url('/images/faqs/bite-up.svg');
      background-repeat: no-repeat;
      background-size: 90px;
      background-position: top right;

      .title {
        font-family: BNPPSansCondensed, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $main-color;
      }
      .description {
        font-family: BNPPSans-Light, sans-serif;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }
      .form-control {
        border-radius: 0px !important;
        border: 0px;
        margin-bottom: 12px;
        resize: none;
        font-family: $font-family;
        font-size: 15px;
        color: $main-color !important;
        padding: 10px;
      }
      .form-control::placeholder {
        font-family: $font-family;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $main-color;
      }
      .select-faq-topic {
        padding: 6px;
      }
      .select-faq-topic option:disabled {
        color: $main-color;
      }
      .select-faq-topic option {
        color: $main-color;
        font-size: 12px;
      }
      .select-faq-topic:invalid {
        color: $main-color;
        font-size: 12px;
      }
      .select-faq-topic:valid {
        color: #000000 !important;
        font-size: 15px;
      }
      .bite-down {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1000;
      }
      .form-group {
        text-align: right;
        margin-left: -5px;
        margin-right: -5px;
        .btn-submit {
          background-color: $main-color;
          font-family: BNPPSansCondensed, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: condensed;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          border: 0px;
          padding: 10px 20px;
        }
      }
      .error-text {
        font-family: BNPPSans-Light, sans-serif;
        font-size: 12px;
      }
    }
  }
}

.notification-box {
  position: absolute;
  top: 130px;
  right: 20px;
  z-index: 1000;
  .alert.alert-noti {
    padding: 20px;
    box-shadow: 6px 6px 20px 0px rgba(140, 137, 140, 1);
    border-radius: 0px;
    color: #fff;
    background: $main-color;
    border-color: #ad0460;
    font-family: BNPPSans-Light, sans-serif;
  }
}

@media only screen and (max-width: 767px) {
  .wrap-faqs-page {
    .wrap-banner {
      .intro {
        .welcome {
          font-size: 30px;
        }
        .description {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
}
