.form {
  &-control {
    padding: 10px;
    border-radius: 0;
  }

  &-button {
    padding: $input-button-padding;
    color: $black;

    &:not([href]) {
      color: $black;
    }
  }

  &-group {
    margin-bottom: 30px;
    text-align: left;
  }

  &-wrap {
    max-width: 500px;
    margin: 60px auto;

    &-left {
      max-width: 500px;
      margin: 20px 0 20px 0;
    }
  }

  &__title {
    text-transform: uppercase;
    margin: 20px 0;
  }

  &__cta__wrap {
    text-align: center;

    @include breakpoint('>=', 'ts') {
      text-align: right;
    }
  }

  &--confirmation {
    display: flex;
    flex-direction: column;
    background-color: rgba(1, 1, 1, 0.1);
    border-radius: $border-radius-small;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin-bottom: 40px;
  }
}

input::-ms-check {
  border: 2px solid $grey-light-dark;
  color: $grey-light-dark;
}

input[type='radio'] {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  appearance: none;
  min-height: 0;
  transition: all .2s linear;
  background: $white;
  vertical-align: text-bottom;

  &:checked {
    border: 6px solid $grey-mid;
  }

  &:hover {
    border: 6px solid $grey-mid;
  }

  &:focus {
    outline: 0;
    border: 6px solid $grey-mid;
  }
}

label {
  padding-right: 15px;
  margin-bottom: 10px;
}

textarea {
  min-height: 132px;
  border-radius: 33px !important;
  resize: none;
}

.checkbox {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0 !important;
  }

  &__btn {
    @extend .btn;
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    padding: 0;
    margin-right: 10px;
    background-color: $light;
    margin-bottom: 0 !important;
    &--active {
      background-color: $grey-mid;
    }
  }
}

.select2-container {
  $root: 'select2';

  .#{$root}-search__field {
    padding: 0 30px !important;
    margin-top: $input-height / 3 !important;
  }

  .#{$root}-selection {
    min-height: $input-height;
    border-radius: $border-radius !important;
    border: 1px solid #ced4da !important;
  }

  .#{$root}-results__option {
    color: $dark;

    &--highlighted {
      background-color: $grey-mid !important;
    }
  }

  .#{$root}-selection__choice {
    border-radius: $border-radius !important;
    background-color: $grey-mid !important;
    padding: 7px 10px !important;

    &__remove {
      color: $light !important;
    }
  }
}
