.countdown {
  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    flex-grow: 1;
    margin: 0 auto;

    @include breakpoint('>=', 'ts') {
      padding: 40px 0 40px 40px;
    }
  }

  &__container {
    @include primary-gradient(90deg);
    color: $light;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 40px 20px 40px 20px;
    border-radius: 42px;
    box-shadow: 0 15px 21px 2px $grey-dark-mid;
    position: relative;

    @include breakpoint('>=', 'ts') {
      flex-wrap: nowrap;
      padding: 40px;
      border-radius: $border-radius;
    }

    &:after {
      background: $light;
      content: '';
      height: 150px;
      position: absolute;
      width: 1px;
      margin: 0 auto;
      left: 0;
      right: 0;
      opacity: .6;
      top: 50%;
      transform: translateY(-50%);

      @include breakpoint('>=', 'ts') {
        display: none;
      }
    }

    &:before {
      background: $light;
      content: '';
      height: 1px;
      position: absolute;
      width: 150px;
      margin: 0 auto;
      left: 0;
      right: 0;
      opacity: .6;
      top: 50%;
      transform: translateY(-50%);

      @include breakpoint('>=', 'ts') {
        display: none;
      }
    }
  }

  &__item {
    flex: 0 50%;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 10px;

    @include breakpoint('>=', 'ts') {
      padding: 20px;

      &:not(:last-of-type):after {
        background: $light;
        content: '';
        height: 70%;
        position: absolute;
        right: 0;
        top: 15%;
        width: 2px;
        opacity: .3;
      }
    }
  }

  &__time {
    font-family: $font-family-light;
    font-size: 2rem;
  }

  &__content {
    padding: 20px;

    &:after {
      background: $light;
      content: '';
      height: 1px;
      position: absolute;
      width: 240px;
      left: 0;
      right: 0;
      opacity: .6;
      top: 70px;
      margin-left: 20px;
    }
  }

  &__button {
    &-wrap {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__text {
    margin: 0;

    @include breakpoint('>=', 'ts') {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
}
