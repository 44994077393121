.error {
  &__contentWrapper {
    display: flex;
    flex-direction: column;
    background-color: $primary;
    border-radius: $border-radius-small;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  &__btnWrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 50px;

    @include breakpoint('>=', 'ms') {
      flex-direction: row;
    }

    @include breakpoint('>=', 'ls') {
      padding-bottom: 200px;
    }

    a:first-of-type {
      @include breakpoint('>=', 'ms') {
        margin-right: 20px;
      }
    }
  }

  &__image {
    width: 100%;
    margin: 90px auto 70px;
    max-height: 340px;
    flex-shrink: 0;
  }

  &__title {
    padding-bottom: 15px;
    font-size: 20px;

    @include breakpoint('>=', 'ms') {
      font-size: 40px;
    }
  }

  &__message {
    margin-bottom: 60px;
  }
}
