.hide, .hidden {
  display: none !important;
}

.u-pb {
  @for $i from 0 through 5 {
    &--#{$i} {
      padding-bottom: $i * 10 + px;
    }
  }
}
