@import 'resources/scss/theme/0-toolkit/_colors.scss';

.jserror {
  position: fixed;
  width: 100vw;
  height: 100vh;
  @include primary-gradient-fill();
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;

  &__content {
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 75px;
    padding-bottom: 37px;
    width: 800px;
    max-width: 90%;
    width: 800px;
    max-width: 90%;
    box-shadow: 0px 2px 13px -7px $black;

    &__exclamation {
      background-color: $danger;
      height: 100px;
      width: 100px;
      padding: 20px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      border: 10px solid $white;

      &__img {
        height: 60px;
      }
    }

    &__header {
      margin: 0;
      margin-bottom: 15px;
    }

    &__body {
      text-align: center;
      max-width: 500px;
    }
  }
}