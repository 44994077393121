
@font-face {
  font-family: 'BNPPSans';
  src:
    url('../fonts/BNPPSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BNPPSans-Light';
  src:
    url('../fonts/BNPPSans-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BNPPSansCondensed';
  src:
    url('../fonts/BNPPSansCondensed.ttf') format('truetype'),
    url('../fonts/BNPPSansCondensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BNPPSansCondensed-Light';
  src:
    url('../fonts/BNPPSansCondensed-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BNPPSansCondensed-Bold';
  src:
    url('../fonts/BNPPSansCondensed.ttf') format('truetype'),
    url('../fonts/BNPPSansCondensed.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BNPPSansCondensed-Bold2';
  src: url('../fonts/BNPPSansCondensed-Bold.woff2') format('woff2'),
  url('../fonts/BNPPSansCondensed-Bold.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

$font-family: 'BNPPSans', sans-serif;
$font-family-body: 'BNPPSans-Light', sans-serif;
$font-family-light: 'BNPPSans-Light', sans-serif;
$font-family-bold: 'BNPPSansCondensed-Bold', sans-serif;
$font-family-condensed: 'BNPPSansCondensed', sans-serif;
$font-family-bold-b: 'BNPPSansCondensed-Bold2', sans-serif;
