.footer {
  background: $white;

  &__container {
    color: $dark;
    display: flex;
    align-items: center;
    padding: 50px 0;
  }

  &__column {
    width: 30%;

    &:last-child {
      width: 70%;
      padding-left: 20px;
    }

    @include breakpoint('>=', 'ts') {
      width: 30%;

      &:last-child {
        width: 70%;
      }
    }
  }

  &__img {
    height: 50px;
    max-width: 100%;
  }

  &__text {
    color: $grey-darker;
    font-family: $font-family-light;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.bottom-bar {
  position: absolute;
  width: 100%;
  height: 25px;
  bottom: 0;
  background-image: url('/images/auth/top-bar.svg');
  overflow: hidden;
}

.bottom-bar-main {
  width: 100%;
  height: 25px;
  background-image: url('/images/auth/top-bar.svg');
  overflow: hidden;
}
