.btn {
  background-color: $grey-mid;
  border: 2px solid $main-color {
    radius: 0;
  };
  color: $light;
  text-transform: uppercase;
  font-family: $font-family-light;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  padding: 16px 30px 12px;
  margin-bottom: 15px;
  transition: all .2s linear;
  cursor: pointer;
  outline: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: lighten($main-color, 30%);
    border: 2px solid lighten($main-color, 30%);
    color: $white;
  }

  &--primary,
  &-primary {
    background-color: $main-color;
  }

  &--white {
    background-color: $white;
    color: $grey-mid;
    border: none;
  }

  &--secondary,
  &-secondary {
    background-color: $grey-mid;
    border: 2px solid $grey-mid;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($grey-mid, 15%);
      border: 2px solid darken($grey-mid, 15%);
      color: $white;
    }
  }

  &--active {
    background-color: $grey;
    border: 2px solid $grey-dark;
    color: $light;
  }

  &-tiny {
    padding: 5px;
  }

  &-default {
    background-color: $light;
    color: $dark;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($light, 15%);
      color: $dark!important;
    }
  }
}
