.wrap-provider-detail-page {

  &-banner-container {
    width: 100%;
  }

  &-img-banner {
    width: 100%;
    height: auto;
    padding: 0 14px;
  }

    .wrap-banner {
        position: relative;
        &--full {
            width: 100%;
        }
        .bite-size-logo {
            position: absolute;
            top: 26px;
            right: 26px;
            z-index: 1000;
            height: 35%;
        }
    }
    .banner-heading {
        font-size: 36px;
    }
    .wrap-provider-content {
        .provider-content {
            font-family: BNPPSans-Light, sans-serif;
        }
        .more-info {
            font-family: BNPPSans-Light, sans-serif;
        }
        .come-and-join-us {
            font-size: 24px;
            font-weight: normal;
            margin-top: 1rem;
            text-transform: uppercase;
            font-family: BNPPSansCondensed, sans-serif;
        }
        .come-join-article {
            font-size: 12px;
            color: white;
            font-family: BNPPSans-Light, sans-serif;
        }
        .wrap-webinar-date {
            border: white 2px solid;
            padding: 0;
            margin: 1rem;
            height: fit-content;
            min-width: 45px;
            .webinar-month {
                color: $white;
                text-align: center;
                font-size: 12px;
                padding: 5px 5px 2px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                font-family: 'BNPPSansCondensed-Bold', sans-serif;
                font-weight: 700;
            }
            .webinar-day {
                color: white;
                text-align: center;
                font-size: 27px;
                padding-top: 5px;
                font-family: 'BNPPSansCondensed-Bold', sans-serif;
            }
        }
        .wrap-btn-watch-video {
            background-color: $main-color;
            text-align: center;
            border: $white 0.5px solid;
            width: 95%;
            padding: 0.5rem 0.5rem 0.25rem 0.5rem;
            margin: 0.5rem 0 1rem 0;
            a {
                font-family: 'BNPPSansCondensed-Bold', sans-serif;
                font-size: 16px;
                color: $white;
                cursor: pointer;
                text-transform: uppercase;
                letter-spacing: 0.5px;
            }
        }
        .wrap-enquiry-box {
            background-color: #f2f2f2;
            margin-top: 1rem;
            .enquiry-box-content {
                padding: 1.5rem;
                padding-top: 0;
                .equiry-title {
                    font-size: 24px;
                    font-weight: normal;
                    color: $main-color;
                }
                .enquiry-intro {
                    font-size: 12px;
                    color: black;
                    font-family: BNPPSans-Light, sans-serif;
                }
                .wrap-enquiry-form {
                    .form-control::placeholder {
                        font-family: BNPPSans-Light, sans-serif;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: $grey-dark;
                    }
                    .form-control {
                        border-radius: 0px;
                        border: 0px;
                        resize: none;
                        font-family: BNPPSans-Light, sans-serif;
                        font-size: 15px;
                    }
                    .enquiry-textarea {
                        margin-top: 1rem;
                        border-radius: 0 !important;
                    }
                    .btn-submit-enquiry {
                        align-self: end;
                        background-color: $main-color;
                        font-family: BNPPSansCondensed, sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: condensed;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.5px;
                        text-align: center;
                        color: #ffffff;
                        border: 0px;
                        padding: 10px 20px 7px 20px;
                        border-radius: 0px;
                        text-transform: uppercase;
                    }
                    .notification-message {
                        margin-right: 3.5rem;
                        color: #a31b6d !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .wrap-provider-detail-page {
        .webinar {
            margin-top: 20px;
        }
    }
}
