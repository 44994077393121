.alert {
  border: 2px solid rgba(255, 255, 255, 0.5) !important; //important required to over-ride bootstrap default border

  &-info {
    border: none;
    background-color: $primary;
    color: $light;
  }

  ul {
    margin-bottom: 0;
  }
}
