.header {
  &--primary {
    font-size: 40px;
    font-family: $font-family-light;
    text-transform: uppercase;
    padding-bottom: 25px;
    text-align: center;

    @include breakpoint('>=', 'ts') {
      text-align: left;
    }
  }

  &--secondary {
    @extend .header--primary;
    text-transform: uppercase;
  }

  &--tertiary {
    font-family: $font-family;
    text-transform: uppercase;
  }
}

.top-bar {
  position: absolute;
  width: 100%;
  height: 25px;
  background-image: url('/images/auth/top-bar.svg');
  overflow: hidden;
}
