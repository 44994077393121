.wrap-top-nav {
  display: flex;
  max-width: 1360px;
  width: 100%;
  height: 68px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .row {
    width: 100%;
  }
  .wrap-left-nav {
    padding-right: 0;
    .left-nav {
      list-style-type: none;
      display: flex;
      padding: 0;
      margin: 0;
    }
    .nav-item {
      background-color: #ffffff;
      padding: 5px 23px 3px 23px;
    }
    .nav-item.active {
      background-color: #e6007d;
    }
    .nav-item a {
      text-decoration: none;
      color: #e6007d;
      text-transform: uppercase;
      font-family: BNPPSansCondensed, sans-serif;
      font-size: 18px;
      font-weight: 300;
      font-stretch: condensed;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .nav-item.active a {
      color: #ffffff;
    }
  }
}

.navigation {
  $root: &;

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  background: transparent;
  margin-bottom: 50px;
  padding: 40px 0;

  @include breakpoint('>=', 'tl') {
    justify-content: space-between;
    background: $grey-slate;
    min-height: 141px;
    padding: 0;
  }

  &__nav {
    margin-left: auto;
    align-items: center;
    padding: 20px 0;

    @include breakpoint('>=', 'tl') {
      padding: 0 0 0 20px;
    }
  }

  &__logo {
    height: 45px;
    width: auto;
  }

  &__toggle {
    appearance: none;
    background-color: transparent;
    height: 25px;
    width: 30px;
    right: 30px;
    top: 50px;
    position: absolute;
    z-index: 1001;

    @include breakpoint('>=', 'tl') {
      display: none;
    }

    &.collapsed {
      #{$root}__icon {
        &:first-child {
          transform: none;
        }

        &:nth-child(2) {
          transform: none;
        }

        &:last-child {
          transform: none;
        }
      }
    }
  }

  &__icon {
    background-color: transparent;
    border: 1px solid $white;
    padding: 1px;
    width: 30px;
    margin-top: 3px;
    display: block;
    height: 2px;
    border-radius: 2px;

    &:first-child {
      transform:translateY(5px) rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotateY(90deg);
    }

    &:last-child {
      transform: translateY(-9px) rotate(-45deg);
    }
  }

  &__collapse {
    left: 0;
    right: 0;
    top: 100%;
    position: absolute;
    z-index: 1002;
    width: 95%;
    margin: 0 auto;
    background-image: linear-gradient(134deg, $primary, $secondary);
    box-shadow: 0 2px 13px -7px rgba(0, 0, 0, 0.7);

    @include breakpoint('>=', 'tl') {
      background: transparent;
      position: relative;
      box-shadow: none;
      width: 100%;
    }
  }

  &__item {
    padding: 0;
  }

  &__link {
    color: $white;
    display: block;
    font-family: $font-family-light;
    font-size: 22px;
    padding: 20px 30px;
    text-transform: lowercase;
    transition: all .2s linear;
    text-decoration: none;

    @include breakpoint('>=', 'tl') {
      font-size: 16px;
      background: transparent;
      position: relative;
      padding: 60px 20px;
      text-transform: uppercase;
    }

    &--active,
    &:hover,
    &:focus {
      color: $white;
      font-family: $font-family;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 425px) {
  .navigation {
    margin-bottom: 1px;
  }
}

.navigation_wrapper {
  .active {
    background-color: #e6007d;

    .item-p {
      color: white !important;
    }
  }

  .item {
    margin: 2vh 1vh 2vh 1vh;
    padding: 0.5vh 0vh 0.5vh 0vh;
  }
}

.item-p {
  font-size: small;
  color: $main-color;
  margin-bottom: 0
}

.navigation_banner {
  background-image: url('../images/provider-list/banner-image@2x.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .banner-description {
    font-size: small;
    text-justify: distribute-center-last;
    margin-top: 1rem;
    margin-bottom: 20vh
  }

  .bitezise {
    height: 25vh;
    width: 10vw;
    margin-top: 1rem;
    margin-right: 0.5rem
  }
}

.banner-heading {
  color: $main-color;
  font-size: 4rem;
  font-family: BNPPSansCondensed, serif;
  margin-top: 10vh
}
.main-color {
  color: $main-color;
}
.main-color-background {
  background-color: $main-color;
}
.bold-text {
  font-size: small;
  font-family: BNPPSansCondensed;
  font-weight: bold
}
.top-header-rainbow {
  width: 100%;
  height: 25px;
  background-image: url('../images/auth/top-bar.svg');
  overflow: hidden;
}
