.provider-list-wrap {
  margin: auto;
  margin-bottom: 70px;

  .list-title {
    padding-left: 2vw;
    padding-right: 2vw;
    margin-bottom: 0;

    p {
      color: #e6007d;
      font-size: 2rem;
      font-family: BNPPSansCondensed, serif;
      margin-bottom: 0
    }
  }

  .provider-card {
    .calendar {
      position: absolute;
      right: 2rem;
      top: 1rem;
      font-family: BNPPSansCondensed, serif;

      .month {
        background-color: #a31b6d;
        color: white;
        font-size: medium;
        text-align: center;
        padding: 0 2px 0 2px
      }

      .date {
        background-color: white;
        color: #a31b6d;
        font-size: large;
        font-weight: bold;
        border: #a31b6d solid 2px;
        text-align: center
      }
    }

    .details {
      font-family: BNPPSansCondensed, serif;

      .view-profile-button {
        background-color: white;
        color: #a31b6d;
        font-size: medium;
        text-align: center;
      }

      .add-to-calendar-button {
        background-color: #a31b6d;
        color: white;
        font-size: medium;
        border: white solid 2px;
        text-align: center;
      }
    }
  }
}

.calendar {
  position: absolute;
  right: 2rem;
  top: 1rem;
  font-family: BNPPSansCondensed, serif;

  .month {
    background-color: #a31b6d;
    color: white;
    font-size: medium;
    text-align: center;
    padding: 0 2px 0 2px
  }

  .date {
    background-color: white;
    color: #a31b6d;
    font-size: large;
    font-weight: bold;
    border: #a31b6d solid 2px;
    text-align: center
  }
}
