// Elements
$border-radius: 100px;
$border-radius-small: 10px;
$button-padding: 1em 2.5em;
$input-padding: 1.3em .8em;
$input-button-padding: .6em .8em;

// Nav
$nav-height: 70px;
$nav-margin-bottom: 2rem;
$toggle-height: 70px;
$toggle-height-lg: $toggle-height * 2.5;
$toggle-bg-scale: 2; // Adjust this value according to number of nav elements

// Forms
$input-height: 48px;

// Footer
$footer-height: 100px;
$footer-margin: 30px;
