// Shame on you!

//this is a toggle in the admin when editing/creating a provider card, when toggled to on it will set the text colour to dark for when the background colours are light (yellow)
.override__text-dark {
  color: $black;

  .title,
  .webinar-month,
  .webinar-day,
  .come-join-article,
  .come-and-join-us,
  .btn-add-to-calendar {
    color: $black !important;
    border-color: $black !important;
  }

  .day,
  .month {
    h3 {
      color: $black !important;
    }
  }

  .description {
    p {
      color: $black !important;
    }
  }

  .wrap-btn-watch-video {
    border-color: $black !important;

    a {
      color: $black !important;
    }
  }
}

// React Select specific
.react-select {
  &__menu {
    color: $dark;
    border-radius: 25px !important;
    max-height: 600px;
    overflow: auto;

    &-list {
      border-radius: 25px !important;
    }
  }

  &__indicators {
    height: 44px;
  }

  &__control {
    border-radius: $border-radius !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

// Wordcloud specific
.word-thank-you-wrap,
.word-submitting-wrap,
.word-error,
.question-error {
  display: none;
}

// Commented for future use
//.submit-word-wrap {
//  display: none;
//}
//
.help-block {
  background-color: $danger;
  border-radius: $border-radius;
  display: block;
  margin-top: 1rem;
  padding: 10px 30px;

  &-token {
    margin: 10px 0;
  }
}

.recaptcha-container {
  & > div > div {
    height: 80px !important;
    margin-bottom: 10px;
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  &-left > div > div {
    justify-content: flex-start !important;
  }

  &-right > div > div {
    justify-content: flex-end !important;
  }
}

// BS overrides
.container-fluid {
  max-width: 1370px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  justify-content: center !important;

  @include breakpoint('>=', 'll') {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $grey-mid;
  border-color: $grey-mid;

  &:hover {
    background-color: lighten($grey-mid, 15%);
    border-color: lighten($grey-mid, 15%);
  }
}

//comet chat override for sizing issue
#cometchat__widget .app__wrapper {
  width: unset;
  height: unset;
}

.Toastify__toast .notification__title {
  font-family: 'BNPPSansCondensed-Bold', sans-serif !important;
  font-size: 24px !important;
  letter-spacing: 0.5px;
}

.Toastify__toast .notification__text {
  font-family: 'BNPPSansCondensed-Light', sans-serif !important;
  font-size: 18px !important;
  letter-spacing: 0.5px;
}

.Toastify__toast .notification__btn--secondary {
  font-family: 'BNPPSansCondensed-Bold', sans-serif !important;
  letter-spacing: 0.5px;
  width: auto !important;
  position: static !important;
}
