// Colours
$white: #ffffff;
$black: #000000;
$grey: #eeeeee;
$grey-light: #cccccc;
$grey-light-mid: #c9c9c9;
$grey-light-dark: #808791;
$grey-mid: #545D61;
$grey-dark-mid: #606975;
$grey-darker: #6D6D6D;
$grey-dark: #282c34;
$grey-slate: #335355;
$dark: #2b363b;
$darkest: #21282d;
$light: #ffffff;
$body-bg: #f5f8fa;
$dark-blue: #444692;
$light-blue: #5d7ebc;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

//Brand colours
$primary: #00AEBE;
$secondary: #36a9e1;
$ash: #2b363b;
$blue-dark: #001423;
$green-bright: #c7d52f;
$yellow: #fedc00;
$main-color: #e6007d;
$secondary-color: #f2923c;
$main-blue: #5D7DBA;

$new-green: #46af8f;

//2023
$purple: #863089;
$off-black: #1D1D1B;

//2024
$light-blue: #33abba;

$primary-gradient: (
  $primary,
  $secondary,
);

$danger: #c04949;
$success: #49c07a;

// Override Bootstrap $brand colours
$brand-primary: #4982c0;
$brand-info: #4982c0;
$brand-success: #49c07a;
$brand-warning: #cbb956;
$brand-danger: #c04949;

$bar-body: #d3efef;

:export {
  primary: $primary;
  white: $white;
  black: $black;
  primaryGradient: $primary-gradient;
  greyMid: $grey-mid;
  barBody: $bar-body;
}
