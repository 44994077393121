.cookie-policy {
  @include primary-gradient(133deg);
  padding: 20px;
  font-family: $font-family;
  position: relative;

  &__title {
    font-family: $font-family;
    margin: 0;
  }

  &__link {
    color: $ash;
    font-weight: 600;
  }

  &__cta {
    background: $grey-mid;
    border: 2px solid $grey-mid;
    border-radius: 6px;
    color: $white;
    transition: all 0.2s linear;
    text-decoration: none;
    padding: 10px 15px;
    margin-top: 5px;
    display: inline-block;

    &:hover {
      background: $grey-light-dark;
      border: 2px solid $grey-light-dark;
      color: $white;
      text-decoration: none;
    }
  }

  &__close {
    &:after {
      content: 'x'; // here is your X(cross) sign.
      color: $grey-mid;
      font-weight: 600;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
